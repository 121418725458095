import React, { useEffect, useState, useRef } from 'react';
import './ChatComponent.css';
import 'amazon-connect-chatjs';
import ChatIcon from './icons/chat.svg';
import SendIcon from './icons/send.svg';
import AttachmentIcon from './icons/attachment.svg';
import CloseIcon from './icons/x.svg';
import CloseChatIcon from './icons/close_chat.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as FileIcon } from './icons/file.svg';
import { Dots, Digital, Spinner } from 'react-activity';
import 'react-activity/dist/Dots.css';
import 'react-activity/dist/Digital.css';
import 'react-activity/dist/Spinner.css';

// const START_CHAT_CONTACT_API = 'https://woejy9tkd9.execute-api.us-west-2.amazonaws.com/prod'; //Test endpoint
const START_CHAT_CONTACT_API = 'https://vatvegj5yj.execute-api.us-west-2.amazonaws.com/prod' // Prod endpoint
let CHAT_LOB = '';
let COMPANY_NAME = '';

let customerChatSession = null;

function ChatComponent(props) {
  const [isOpen, setisOpen] = useState(false);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isChatEnded, setIsChatEnded] = useState(false);
  const [isConnectionFailure, setIsConnectionFailure] = useState(false);
  const [isFileSendMode, setIsFileSendMode] = useState(false);
  const [isFileSending, setIsFileSending] = useState(false);
  const [clientMessage, setClientMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const inputFile = useRef(null);
  const downloadLink = useRef(null);
  const chatComponent = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [chatDetails, setChatDetails] = useState(null);
  const [companyName, setCompanyName] = useState('');

  let endChatTimer = null;

  //Set company name from parent data attribute.
  useEffect(() => {
    COMPANY_NAME = chatComponent.current.parentElement.dataset.company;
    setCompanyName(COMPANY_NAME);
  }, [chatComponent]);

  //Setup global configuration to avoid using default values
  useEffect(() => {
    setupChatConfig();
    document.addEventListener('open_chat', () => {
      setisOpen(true);
    });
    document.addEventListener('close_chat', () => {
      setisOpen(false);
    });
    if (chatComponent.current.parentElement.dataset.lob) {
      CHAT_LOB = chatComponent.current.parentElement.dataset.lob;
    } else {
      console.error('Missing lob attribute');
    }

    if (chatComponent.current.parentElement.dataset.company) {
      COMPANY_NAME = chatComponent.current.parentElement.dataset.company;
      setCompanyName(COMPANY_NAME);
    }
  }, []);

  //establish Chat connection if the window is open and an existing connection hasn't been started.
  useEffect(() => {
    if (isOpen && !isChatStarted) {
      if (chatComponent.current.parentElement.dataset.lob) {
        CHAT_LOB = chatComponent.current.parentElement.dataset.lob;
      } else {
        console.error('Missing lob attribute');
      }
      connectChat();
    }
  }, [isOpen]);

  const setupChatConfig = () => {
    if (!isChatStarted) {
      window.connect.ChatSession.setGlobalConfig({
        loggerConfig: {
          // optional, the logging configuration. If omitted, no logging occurs
          logger: {
            // optional, a logger object implementation
            debug: (msg) => console.debug(msg), // REQUIRED, can be any function
            info: (msg) => console.info(msg), // REQUIRED, can be any function
            warn: (msg) => console.warn(msg), // REQUIRED, can be any function
            error: (msg) => console.error(msg), // REQUIRED, can be any function
          },
          level: window.connect.ChatSession.LogLevel.WARN, // optional, defaults to: `connect.ChatSession.LogLevel.INFO`
        },
        region: 'us-west-2',
      });
    }
  };

  const connectChat = async () => {
    if (isOpen) {
      try {
        setIsChatStarted(false);
        setIsConnectionFailure(false);
        setIsChatEnded(false);
        let chatStartResp = await fetch(START_CHAT_CONTACT_API, {
          method: 'POST',
          body: JSON.stringify({ lob: CHAT_LOB }),
        });
        let jsonResp = await chatStartResp.json();
        customerChatSession = window.connect.ChatSession.create({
          chatDetails: {
            contactId: jsonResp.ContactId, // REQUIRED
            participantId: jsonResp.ParticipantId, // REQUIRED
            participantToken: jsonResp.ParticipantToken, // REQUIRED
          },
          options: {
            // optional
            region: 'us-west-2', // optional, defaults to `region` set in `connect.ChatSession.setGlobalConfig()`
          },
          type: window.connect.ChatSession.SessionTypes.CUSTOMER, // REQUIRED
        });

        setChatDetails(jsonResp);

        const { connectCalled, connectSuccess } = await customerChatSession.connect({
          ConnectParticipant: true,
          Type: 'WEBSOCKET',
        });
        if (connectSuccess) {
          customerChatSession.onMessage((e) => handleIncomingMessage(e));
          customerChatSession.onEnded((e) => handleChatEnded(e));
          setIsChatStarted(true);
          setIsChatEnded(false);
        } else {
          throw new Error("Couldn't Establish Customer Chat Session Connection");
        }
      } catch (err) {
        console.error('Error Connecting Chat');
        setIsConnectionFailure(true);
        setIsChatStarted(false);
      }
    }
  };

  ////////////////
  // Event Handlers
  ////////////////

  /**
   * Handles the Chat Icon Click.
   */
  const handleChatIconPress = async () => {
    setisOpen(!isOpen);
  };

  /**
   * Handles messages received from Connect
   */
  const handleIncomingMessage = (event) => {
    //console.log(event);
    if (event.data.Type === 'MESSAGE') {
      if (event.data.ContentType === 'application/vnd.amazonaws.connect.message.interactive') {
        let new_message = {
          type: 'LIST_PICKER',
          message: JSON.parse(event.data.Content),
          id: event.data.Id,
        };
        setMessages((messages) => [...messages, new_message]);
      } else {
        // let tmp_message = messages;
        let new_message = {
          type: event.data.ParticipantRole === 'CUSTOMER' ? 'CLIENT' : 'AGENT',
          message: event.data.Content,
          status: 'received',
          id: event.data.Id,
          sender: (event.data.DisplayName === "BOT" || event.data.DisplayName === "SYSTEM_MESSAGE") ? companyName : (event.data.DisplayName ? event.data.DisplayName : companyName),
        };
        setMessages((messages) => [...messages, new_message]);
      }
    } else if (event.data.Type === 'ATTACHMENT') {
      if (event.data.ParticipantRole === 'AGENT') {
        let new_message = {
          type: 'AGENT_FILE',
          title: event.data.Attachments[0].AttachmentName,
          attachmentId: event.data.Attachments[0].AttachmentId,
          id: event.data.Id,
        };
        setMessages((messages) => [...messages, new_message]);
      }
      if (event.data.ParticipantRole === 'CUSTOMER') {
        let new_message = {
          type: 'CLIENT_FILE',
          title: event.data.Attachments[0].AttachmentName,
          attachmentId: event.data.Attachments[0].AttachmentId,
          id: event.data.Id,
        };
        setMessages((messages) => [...messages, new_message]);
      }
    } else if (event.data.Type === 'EVENT') {
      if (
        // !isChatEnded &&
        event.data.ContentType === 'application/vnd.amazonaws.connect.event.chat.ended' ||
        event.data.ContentType === 'application/vnd.amazonaws.connect.event.participant.left'
      ) {
        //setIsChatEnded(true);
        if (!endChatTimer) {
          endChatTimer = setTimeout(() => {
            setIsChatEnded(true);
            let new_message = {
              type: 'CHAT_ENDED',
              message: 'Chat Ended',
              sub_message: 'Send a message to start a new chat',
              id: event.data.Id,
            };
            setMessages((messages) => [...messages, new_message]);
            endChatTimer = null;
          }, 1000);
        }

        // if(messages[messages.length-1].type !== "CHAT_ENDED"){
        // let new_message = {
        //   type: 'CHAT_ENDED',
        //   message: 'Chat Ended',
        //   sub_message: 'Send a message to start a new chat',
        //   id: event.data.Id,
        // };
        // setMessages((messages) => [...messages, new_message]);
      } else if (
        event.data.ParticipantRole === 'AGENT' &&
        event.data.ContentType == 'application/vnd.amazonaws.connect.event.participant.joined'
      ) {
        let new_message = {
          type: 'AGENT_JOINED',
          displayName: event.data.DisplayName,
          id: event.data.Id,
        };
        setMessages((messages) => [...messages, new_message]);
      }
    }
  };

  /**
   * Handles when Chat is ended
   */
  const handleChatEnded = (event) => {
    setIsChatEnded(true);
  };

  /**
   * Handles Enter Key press on chat text input
   * @param {*} event
   */
  const handleKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      sendMessage();
    }
  };

  /**
   * Handles the uploaded file
   */
  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
    setIsFileSendMode(true);
  };

  /**
   * Handles File Attachment Icon Click
   */
  const handleFileUploadButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  /////////////
  // Actions
  /////////////

  /**
   * Sends client messages to Connect
   */
  const sendMessage = async () => {
    if (isChatEnded) {
      try {
        customerChatSession = null;
        const resp = await connectChat();
        // setIsChatStarted(true);
        setIsChatEnded(false);
        //await sendMessage();
      } catch (err) {
        setIsChatEnded(false);
      }
      return;
    } else if (isFileSendMode) {
      let resp = await sendFile(selectedFile);
    } else if (clientMessage.length > 0) {
      const awsSdkResponse = await customerChatSession.sendMessage({
        contentType: 'text/plain',
        message: clientMessage,
      });
      const { AbsoluteTime, Id } = awsSdkResponse.data;
      setClientMessage('');
    }
  };

  /**
   * Sends option selected from ListPicker
   */
  const sendListPickerOption = async (title) => {
    if (title.length > 0) {
      const awsSdkResponse = await customerChatSession.sendMessage({
        contentType: 'text/plain',
        message: title,
      });
      const { AbsoluteTime, Id } = awsSdkResponse.data;
    }
  };

  /**
   * Sends client selected file attachment
   */
  const sendFile = async (file) => {
    try {
      setIsFileSending(true);
      const awsSdkResponse = await customerChatSession.sendAttachment({
        attachment: file,
      });
      setIsFileSendMode(false);
      setIsFileSending(false);
      setSelectedFile(null);
      return awsSdkResponse;
    } catch (err) {
      setIsFileSendMode(false);
      setIsFileSending(false);
      setSelectedFile(null);
      console.error(err.message);
    }
  };

  /**
   * Downloads Chat Transcript
   * @deprecated
   */
  const downladTranscript = async () => {
    const awsSdkResponse = await customerChatSession.getTranscript({
      maxResults: 100,
      sortOrder: 'ASCENDING',
    });
    const { InitialContactId, NextToken, Transcript } = awsSdkResponse.data;
  };

  const downloadFile = async (attachmentId) => {
    const awsSdkResponse = await customerChatSession.downloadAttachment({
      attachmentId: attachmentId,
    });
    let link = URL.createObjectURL(awsSdkResponse);
    downloadLink.current.href = link;
    downloadLink.current.click();
    return awsSdkResponse;
  };

  /**
   * Ends the current Chat conversation
   */
  const endChat = async () => {
    const awsSdkResponse = await customerChatSession.disconnectParticipant();
    setIsChatEnded(true);
  };

  const componentMapper = (message) => {
    switch (message.type) {
      case 'AGENT':
        return agentChatMessage(message);
      case 'CLIENT':
        return clientChatMessage(message);
      case 'CHAT_ENDED':
        return chatEndedMessage(message);
      case 'LIST_PICKER':
        return listPickerMessage(message);
      case 'CLIENT_FILE':
        return clientFileMessage(message);
      case 'AGENT_FILE':
        return agentFileMessage(message);
      case 'AGENT_JOINED':
        return agentJoinedMessage(message);
      default:
        break;
    }
  };

  ////////////////
  // Components
  ////////////////

  /**
   * Error Message Component
   */
  const connectionErrorMessage = (props) => {
    return (
      <div
        aria-label="Error Connecting Message"
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <h2 className="connection_error_header">Could not connect to {companyName} Chat</h2>
        <h4 className="connection_error_subheader">Make sure you are connected to the internet</h4>
        <button
          aria-label="Retry Connection?"
          onClick={() => connectChat()}
          className="connection_error_button"
        >
          Retry Connection
        </button>
      </div>
    );
  };

  /**
   * Connecting Message Component
   */
  const connectingMessage = (props) => {
    return (
      <div aria-label="Chat Connecting Message" className="connecting_message_container">
        <Dots color={COMPANY_NAME.toUpperCase() == 'RENOWN' ? '#490E6F' : '#E60D2E'} />
        <p className="connecting_message">Connecting to Agent</p>
      </div>
    );
  };

  /**
   * Client Chat Message Component
   */
  const clientChatMessage = (props) => {
    return (
      <li aria-label="User Chat Message" key={props.id} className="client_message">
        <p>{props.message}</p>
      </li>
    );
  };

  /**
   * Agent Chat Message Component
   */
  const agentChatMessage = (props) => {
    return (
      <li aria-label="Agent Chat Message" key={props.id} className="agent_message">
        <p className="agent_message_sender">{props.sender}</p>
        <div className="agent_message_container">
          <p className="agent_message_text">{props.message}</p>
        </div>
      </li>
    );
  };

  /**
   * List Picker Message Component
   */
  const listPickerMessage = (props) => {
    return (
      <div aria-label="List Picker Message" className="list_picker">
        <p className="list_picker_title">{props.message.data.content.title}</p>
        <p className="list_picker_subtitle">{props.message.data.content.subtitle}</p>
        <div className="list_picker_options_container">
          {props.message.data.content.elements.map((element, id) => {
            return (
              <button
                aria-label={element.title}
                onClick={(e) => sendListPickerOption(element.title)}
                className="list_picker_option"
                key={id}
              >
                {element.title}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  /**
   * Chat Ended Message Component
   */
  const chatEndedMessage = (props) => {
    return (
      <li aria-label="Chat Ended Message" className="chat_ended_message" key={props.id}>
        <div className="chat_ended_container">
          <p className="chat_ended_title">{props.message}</p>
          <p className="chat_ended_subtitle">{props.sub_message}</p>
        </div>
      </li>
    );
  };

  /**
   *
   * @param {*} props
   * @returns
   */
  const clientFileMessage = (props) => {
    return (
      <li aria-label="User Uploaded File Message" key={props.id} className="client_message">
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FileIcon style={{ height: '15px' }} fill="#5e5e5c" />
            <p style={{ color: '#5e5e5c', fontSize: '8pt' }}>File Attachment</p>
          </div>

          <button
            aria-label="Download Attachment"
            onClick={() => downloadFile(props.attachmentId)}
            className="client_file_button"
          >
            <DownloadIcon style={{ height: '17px', width: '17px' }} fill="#5e5e5c" />
          </button>
        </span>
        <div className="client_file_container">
          <p style={{ fontStyle: 'italic' }}>{props.title}</p>
        </div>
      </li>
    );
  };

  const agentFileMessage = (props) => {
    return (
      <li key={props.id} className="agent_file_message">
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <img style={{ height: '15px'}} src={FileIcon} /> */}
            <FileIcon style={{ height: '15px' }} fill="#38828f" />
            <p style={{ color: '#225159', fontSize: '8pt' }}>File Attachment</p>
          </div>

          <button onClick={() => downloadFile(props.attachmentId)} className="agent_file_button">
            {/* <img src={DownloadIcon}/> */}
            <DownloadIcon style={{ height: '17px', width: '17px' }} fill="#38828f" />
          </button>
        </span>
        <div className="client_file_container">
          <p style={{ fontStyle: 'italic' }}>{props.title}</p>
        </div>
      </li>
    );
  };

  const agentJoinedMessage = (props) => {
    return (
      <div className="agent_joined_message">
        <p className="agent_joined_text">{props.displayName} has joined the chat.</p>
      </div>
    );
  };

  //Main render
  return (
    <>
      <button
        aria-label="Open Chat"
        aria-controls="chat_widget"
        ref={chatComponent}
        style={{ visibility: isOpen ? 'visible' : 'inherit' }}
        type="button"
        className={['chat_button', companyName.toUpperCase() === 'RENOWN' ? 'renown' : 'hth_1969'].join(
          ' '
        )}
        onClick={() => handleChatIconPress()}
      >
        <img
          aria-hidden="true"
          className="chat_icon"
          alt="Open Chat Widget"
          src={!isOpen ? ChatIcon : CloseChatIcon}
        />
      </button>
      {isOpen && (
        <div aria-expanded={isOpen} id="chat_widget" className="chat_container">
          <div className={companyName.toUpperCase() == 'RENOWN' ? 'renown_header' : 'hth_header'}>
            <p className="header_title">How can we help?</p>
          </div>
          <div className="message_container">
            <ul>
              {}
              {!isChatStarted && !isConnectionFailure && connectingMessage()}
              {isConnectionFailure && connectionErrorMessage()}
              {isChatStarted &&
                messages.map((item) => {
                  return componentMapper(item);
                })}
            </ul>
          </div>

          <div className="input_container">
            {isFileSendMode ? (
              isFileSending ? (
                <>
                  <Digital size={8} color="gray" style={{ marginLeft: '10px' }} />
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="file_selected">
                      <div>
                        <p>{selectedFile ? selectedFile.name : ''}</p>
                      </div>
                      <button
                        aria-label="Remove File"
                        onClick={() => setIsFileSendMode(false)}
                        className="file_selected_cancel_button"
                      >
                        <img aria-hidden="true" src={CloseIcon} />
                      </button>
                    </div>
                  </div>
                </>
              )
            ) : (
              <input
                aria-label="Type a Message"
                aria-required="true"
                value={clientMessage}
                onChange={(event) => {
                  setClientMessage(event.target.value);
                }}
                onKeyPress={(event) => handleKeyDown(event)}
                placeholder="Type a message"
                className="client_input"
                type="text"
              />
            )}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* Hidden elements for file upload/download*/}
              <input
                aria-hidden="true"
                onChange={(e) => handleFileUpload(e)}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
              <a aria-hidden="true" download ref={downloadLink} style={{ display: 'none' }}></a>
              <button
                aria-label="Select File Attachment"
                disabled={!isChatStarted || isChatEnded}
                onClick={handleFileUploadButtonClick}
                className="send_button"
                // style={{opacity: !isChatStarted || isChatEnded ? 0.5 : 1}}
              >
                <img aria-hidden="true" alt="Upload Attachment Icon" src={AttachmentIcon} />
              </button>
              <button
                // style={{ opacity: isFileSendMode ? 1 : clientMessage.length < 1 ? 0.5 : 1 }}
                disabled={
                  !isChatStarted ||
                  (!isFileSendMode && clientMessage.length < 1) ||
                  isFileSending ||
                  (isFileSendMode && selectedFile == null)
                }
                onClick={() => sendMessage()}
                className="send_button"
                aria-label="Send"
              >
                {isFileSending ? (
                  <Spinner size={9} color="gray" />
                ) : (
                  <img aria-hidden="true" alt="Send Message Icon" src={SendIcon} />
                )}
              </button>
            </div>
          </div>

          <div className="footer">
            {/* <button
              style={{ visibility: isChatStarted ? 'visible' : 'hidden' }}
              onClick={() => downladTranscript()}
              className="endchat_button"
            >
              <img className="close_icon" src={DownloadIcon} />
              Save Transcript
            </button> */}
            <button
              style={{ visibility: isChatStarted && !isChatEnded ? 'visible' : 'hidden' }}
              onClick={() => endChat()}
              className="endchat_button"
              aria-label="End Chat"
            >
              End Chat
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatComponent;
